<template>
  <div>
    <div
      v-for="reference in references"
      :key="reference.id"
      class="row m-1"
    >
      <div
        class="col-4"
      >
        {{ $store.getters['directories/outKey'].filter(i => i.code === reference.out_key).pop()?.title ?? reference.out_key }}
      </div>
      <div class="col-8">
        {{ reference.out_id }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    references: {
      type: Array,
      default() {
        return []
      },
    },
  }
}
</script>
