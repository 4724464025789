<template>
  <div>
    <v-table
      ref="operations-table"
      path="/operations?populate=driver-reference"
      :fields="driverOperationsColumns"
      :filter="tableFilter"
      table-sm
    >
      <template #create-button>
        <div class="d-flex justify-content-end col-md-8 col-12">
          <div class="col">
            <b-input-group>
              <b-form-input
                :value="operationsFilter.date__gte"
                type="date"
                @change="changeDateStartHandler"
              />
              <b-form-input
                :value="operationsFilter.date__lte"
                type="date"
                @change="changeDateEndHandler"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="$bvModal.show('operations-filter')"
                >
                  <feather-icon icon="MoreVerticalIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            variant="primary"
            @click="$refs['operations-create'].show()"
          >Добавить
          </b-button>
        </div>
      </template>
      <template #cell(serial)="data">
        <v-table-serial-column :data="data"/>
      </template>
    </v-table>
    <b-modal
      id="operations-filter"
      hide-footer
      title="Фильтрация"
      size="sm"
    >
      <operations-filter-form
        :value="operationsFilter"
        @submit="applyFilter"
      />
    </b-modal>
    <b-modal
      ref="operations-create"
      title="Добавить операцию"
      hide-footer
    >
      <operations-create-form
        :driver-id="driverId"
        @cancel="$refs['operations-create'].hide()"
        @created="onOperationCreatedHandler"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import OperationsFilterForm from '@/views/operations/components/OperationsFilterForm.vue'
import OperationsCreateForm from '@/views/operations/components/OperationCreateForm.vue'
import VTable, {
  VTableSerialColumn,
} from '@/views/components/vtable'
import moment from 'moment'
import { driverOperationsColumns } from '../driversData'

export default {
  components: {
    VTable,
    VTableSerialColumn,
    BModal,
    BButton,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    OperationsFilterForm,
    OperationsCreateForm,
  },
  props: {
    driverId: {
      type: Number,
      required: true,
    },
    last_operation_date: {
      type: String,
    },
  },
  data() {
    return {
      driverOperationsColumns,
      operationsFilter: {
        date__gte: null,
        date__lte: null,
        type: '',
        out_key: '',
      },
    }
  },
  computed: {
    tableFilter() {
      return {
        driver_id: this.driverId,
        ...this.operationsFilter,
      }
    },
  },
  watch: {
    last_operation_date() {
      this.setDateFilter()
    },
  },
  created() {
    this.setDateFilter()
  },
  methods: {
    setDateFilter() {
      const lte = moment(this.last_operation_date)
      this.operationsFilter.date__lte = lte.format('YYYY-MM-DD')
      this.operationsFilter.date__gte = lte.subtract(7, 'days').format('YYYY-MM-DD')
    },
    applyFilter(data) {
      this.operationsFilter = { ...data }
      this.$bvModal.hide('operations-filter')
    },
    changeDateStartHandler(date) {
      this.operationsFilter.date__gte = date
    },
    changeDateEndHandler(date) {
      this.operationsFilter.date__lte = date
    },
    onOperationCreatedHandler() {
      this.$refs['operations-create'].hide()
      this.$refs['operations-table'].refresh()
    },
  },
}
</script>
