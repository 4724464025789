<template>
  <div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Номер договора</strong>
      </div>
      <div class="col-8" style="position: relative">
        {{ driver | contractNumber }}
        <b-button
          class="rounded-circle"
          style="position: absolute; right: 76px; padding: 6px"
          variant="primary"
          @click.prevent="onCreateContractHandler"
        >
          <feather-icon
            icon="PlusIcon"
            size="26"
          />
        </b-button>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong class="small">Договоры</strong>
      </div>
      <div
        class="col-8"
      >
        <div
          v-for="item in contractDates"
          :key="item.date_start"
        >
          {{ item.date_start | dateFormat(true, false) }} - {{ item.date_end | dateFormat(true, false) }}
          <span
            v-if="item.is_current"
            class="text-success"
          >
            <feather-icon
              icon="CheckIcon"
              size="24"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Yandex договор</strong>
      </div>
      <div class="col-8">
        {{ driver.yandex_contract }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Фамилия Имя Отчество</strong>
      </div>
      <div class="col-8">
        {{ driver.last_name }} {{ driver.first_name }} {{ driver.middle_name }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Адрес</strong>
      </div>
      <div class="col-8">
        {{ driver.address }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Телефон</strong>
      </div>
      <div class="col-8">
        {{ driver.phone }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Email</strong>
      </div>
      <div class="col-8">
        {{ driver.email }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Паспорт</strong>
      </div>
      <div class="col-8">
        Серия/Номер: {{ driver.passport_number }}
        <br>
        Код подразделения: {{ driver.passport_code }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>СНИЛС</strong>
      </div>
      <div class="col-8">
        {{ driver.snils_number }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Водительское удостоверение</strong>
      </div>
      <div class="col-8">
        Серия/Номер: {{ driver.license_number }}
        <br>
        Дата выдачи: {{ driver.license_date | dateFormat(true, false) }}
      </div>
    </div>
    <hr>
    <div
      v-for="contract in driver.car_contract"
      :key="contract.id"
      class="row mb-1"
    >
      <div class="col-4 text-right">
        <strong>{{ `Аренда ${contract.type === 'day' ? 'сутки' : 'смена'}` }}</strong>
      </div>
      <div class="col-8 text-left">
        {{ contract.date_end }}
      </div>
    </div>
    <!--
    <hr>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Отправлять деньги</strong>
      </div>
      <div class="col-8">
        {{ driver.payments_send ? 'Да' : 'Нет' }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Заявление о выплате</strong>
      </div>
      <div class="col-8">
        {{ driver.payments_document ? 'Да' : 'Нет' }}
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-right">
        <strong>Свыше суммы</strong>
      </div>
      <div class="col-8">
        {{ driver.payments_over }}
      </div>
    </div>
    -->
    <!--
    <hr>
    <div>
      <h5
        class="pl-5 mb-2"
        style="position: relative"
      >
        Банковские реквизиты
        <b-button
          variant="primary"
          class="rounded-circle"
          style="position: absolute; right: 64px; top: 1px; padding: 6px"
          @click.prevent="onCreateBankHandler"
        >
          <feather-icon
            size="26"
            icon="PlusIcon"
          />
        </b-button>
      </h5>
      <div
        v-for="(item, index) in driver.bank_details"
        :key="index"
        class="row mb-2"
      >
        <div class="col-4 text-right">
          <strong>{{ item.date_start | dateFormat(true, false) }} -
            {{ item.date_end ? dateFormat(item.date_end, true, false) : 'По настоящее время' }}</strong>
        </div>
        <div class="col border-left-success">
          <div class="row">
            <div class="col-3 text-right">
              <strong>Бик</strong>
            </div>
            <div class="col-9">
              {{ item.bik }}
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-right">
              <strong>Инн</strong>
            </div>
            <div class="col-9">
              {{ item.inn }}
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-right">
              <strong>Номер счета</strong>
            </div>
            <div class="col-9">
              {{ item.score }}
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-right">
              <strong>Получатель</strong>
            </div>
            <div class="col-9">
              {{ item.recipient }}
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-right">
              <strong>Назначения платежа</strong>
            </div>
            <div class="col-9">
              {{ item.appointment }}
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import moment from 'moment'
import {
  BButton,
} from 'bootstrap-vue'
import { dateFormat } from '@/libs/filters'

export default {
  components: {
    BButton,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contractDates() {
      const now = moment()
      return this.driver.contracts?.map(item => ({
        ...item,
        is_current: now.isBetween(item.date_start, item.date_end, undefined, '[]'),
      }))
    },
  },
  methods: {
    dateFormat,
    onCreateContractHandler() {
      //
    },
    onCreateBankHandler() {
      //
    },
  },
}
</script>
