<template>
  <div>
    <b-form-group
      v-if="driverId === null"
      label="Водитель"
      label-for="driver_name"
      label-cols="4"
    >
      <b-form-input
        id="driver_name"
        v-model="driverDisplayName"
        @keyup="driverNameKeyupHandler"
      />
      <div
        class="position-relative"
        style="z-index: 999"
      >
        <b-list-group class="position-absolute full-width">
          <b-list-group-item
            v-for="driver in driversSelectList"
            :key="driver.id"
            @click="clickDriverSelectHandler(driver)"
          >{{ driver.last_name }} {{ driver.first_name }} {{ driver.middle_name }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-form-group>
    <div v-if="formData.driver_id">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form @submit.prevent="handleSubmit(submitHandler)">
          <validation-provider #default="ctx">
            <b-form-group
              label="Категория"
              label-for="type"
              label-cols="4"
            >
              <v-select
                id="type"
                v-model="formData.type"
                :state="getValidationState(ctx)"
                :options="categories"
                :reduce="v => v.key"
                placeholder="Select..."
              />
            </b-form-group>
          </validation-provider>
          <validation-provider #default="ctx">
            <b-form-group
              label="Сумма"
              label-for="amount"
              label-cols="4"
            >
              <b-form-input
                id="amount"
                v-model="formData.amount"
                :state="getValidationState(ctx)"
              />
            </b-form-group>
          </validation-provider>
          <div
            role="group"
            class="form-row form-group d-block mt-1"
          >
            <label
              for="is_cashe"
              class="col-4 col-form-label pt-0"
            >Наличные</label>
            <div class="float-right">
              <b-form-checkbox
                id="is_cashe"
                v-model="formData.is_cache"
                switch
              />
            </div>
          </div>
          <validation-provider
            #default="ctx"
          >
            <b-form-group
              label="Коментарий"
              label-for="comment"
              label-cols="4"
            >
              <b-form-textarea
                id="comment"
                v-model="formData.description"
                :state="getValidationState(ctx)"
              />
            </b-form-group>
          </validation-provider>
          <hr />
          <div class="d-flex justify-content-end">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="$emit('cancel')"
            >
              Отмена
            </b-button>
            <b-button
              variant="primary"
              class="ml-2"
              type="submit"
            >
              Сохранить
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormCheckbox, BFormGroup, BFormInput, BFormTextarea, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BButton,
    VSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    driverId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      driverDisplayName: '',
      driverNameWaitResult: false,
      driverNameKeyupTimeout: null,
      driversSelectList: [],
      formData: {
        driver_id: null,
        amount: 0,
        type: null,
        is_cache: false,
        description: '',
      },
    }
  },
  computed: {
    categories() {
      return this.$store.getters['directories/operation'].map(item => ({
        key: item.code,
        label: item.title,
        description: item.description,
      }))
    },
  },
  mounted() {
    if (this.driverId !== null) {
      this.formData.driver_id = this.driverId
    }
  },
  methods: {
    submitHandler() {
      this.$axios.post('operations', { ...this.formData }).then(response => {
        if (response.status === 201) {
          this.$emit('created')
          this.$emit('complete')
        }
      }).catch(error => {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Ошибка',
          variant: 'danger',
          autoHideDelay: 5000,
        })
      })
    },
    clickDriverSelectHandler(driver) {
      this.formData.driver_id = driver.id
      this.driverDisplayName = [driver.last_name, driver.first_name, driver.middle_name].join(' ')
      this.driversSelectList = []
    },
    driverNameKeyupHandler() {
      clearTimeout(this.driverNameKeyupTimeout)
      this.driverNameKeyupTimeout = setTimeout(() => {
        if (!this.driverNameWaitResult && this.driverDisplayName && this.driverDisplayName.length > 3) {
          this.driverNameWaitResult = true
          this.$axios.get('drivers', { params: { search: this.driverDisplayName } })
            .then(response => {
              this.driversSelectList = response.data.data
            })
            .catch(() => {
              this.driversSelectList = []
            })
            .finally(() => {
              this.driverNameWaitResult = false
            })
        } else {
          this.driversSelectList = []
        }
      }, 400)
    },
  },
}
</script>

<script setup>
const {
  // eslint-disable-next-line no-unused-vars
  refFormObserver,
  // eslint-disable-next-line no-unused-vars
  getValidationState,
} = formValidation(() => {
})
</script>
