<template>
  <div>
    <b-overlay :show="!ready">
      <b-card header-class="justify-content-between">
        <template #header>
          <h4>{{ driverDisplayName }}</h4>
          <b-button-group>
            <b-button
              variant="outline-primary"
              @click="prevHandler"
            >
              <feather-icon
                icon="ChevronLeftIcon"
              />
            </b-button>
            <b-button
              variant="outline-primary"
              @click="openDriverForm"
            >
              <feather-icon
                icon="EditIcon"
                class="text-warning"
              />
            </b-button>
            <b-button
              variant="outline-primary"
              @click="$bvModal.show('print-menu')"
            >
              <feather-icon
                icon="PrinterIcon"
                class="text-secondary"
              />
            </b-button>
            <b-button
              variant="outline-primary"
              @click="deleteDriverHandler"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
            <b-button
              variant="outline-primary"
              @click="nextHandler"
            >
              <feather-icon
                icon="ChevronRightIcon"
              />
            </b-button>
          </b-button-group>
        </template>
        <b-tabs>
          <b-tab
            href="#general"
            title="Основная информация"
          >
            <basic-information :driver="driver" />
          </b-tab>
          <b-tab
            v-if="driver.id"
            title="Операции"
          >
            <driver-operations
              :driver-id="driver.id"
              :last_operation_date="driver.last_operation_date"
            />
          </b-tab>
          <b-tab
            v-if="driver.references.length > 0"
            title="Аккаунты"
          >
            <accounts :references="driver.references" />
          </b-tab>
          <b-tab
            v-if="driver.car_contract"
            title="Договор аренды"
          >
            <div
              v-for="contract in driver.car_contract"
              :key="contract.id"
            >
              <h6>Аренда {{ contract.type === 'change' ? 'смена' : 'сутки' }}</h6>
              <data-view
                :data="[
                  { value: contract.number, label: 'Номер' },
                  { value: contract.date_start, label: 'Подписан'},
                  { value: contract.date_end, label: 'Действителен до'},
                  ...(contract.type === 'change' ? [] : [
                    { value: `${contract.params?.deposit_made ?? '-'} руб.`, label: 'Залог внес' },
                    { value: `${contract.params?.deposit_up ?? '-'} руб.`, label: 'Залог До' },
                    { value: `${contract.params?.amount_rent ?? '-'} руб.`, label: 'Размер аренды' },
                    { value: contract.params?.payment_schedule ?? '-', label: 'График платежей' },
                  ])
                ]"
              />
            </div>
          </b-tab>
          <b-tab
            v-if="driver.id"
            title="Задолженности по авто"
          >
            <driver-debts
              :driver-id="driver.id"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <!-- Edit Modal -->
    <b-modal
      id="driver-form"
      size="lg"
      title="Редактитровать"
      hide-footer
      @hide="onDriverFormHideHandler"
    >
      <driver-contract-form
        :driver-id="driver.id"
        @complete="closeDriverForm"
        @updated="updatedDriverHandler"
      />
    </b-modal>
    <!-- Print Modal -->
    <b-modal
      id="print-menu"
      title="Печать"
      hide-footer
    >
      <div class="row">
        <b-form-group
          class="col-10"
          label="Договор"
          label-for="contract_select"
        >
          <v-select
            id="contract_select"
            v-model="contractToPrint"
            :options="contractsOptions"
            placeholder="Select....."
          />
        </b-form-group>
        <div class="col-2 mt-2">
          <b-button
            :variant="contractToPrint ? 'primary' : 'secondary'"
            :disabled="!contractToPrint"
            @click="onContractPrintClickHandler"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </div>
      </div>
      <div class="row">
        <b-form-group
          class="col-10"
          label="Заявление в банк"
          label-for="bank_select"
        >
          <v-select
            id="bank_select"
            v-model="bankToPrint"
            :options="bankOptions"
            placeholder="Select....."
          />
        </b-form-group>
        <div class="col-2 mt-2">
          <b-button
            :variant="bankToPrint ? 'primary' : 'secondary'"
            :disabled="!bankToPrint"
            @click="onBankPrintClickHandler"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </div>
      </div>
      <div class="row">
        <b-form-group
          class="col-10"
          label="Отчет"
          label-for="date_start"
        >
          <b-input-group>
            <b-form-input
              id="date_start"
              v-model="reportToPrint.date_start"
              type="date"
            />
            <b-form-input
              v-model="reportToPrint.date_end"
              type="date"
            />
          </b-input-group>
        </b-form-group>
        <div class="col-2 mt-2">
          <b-button
            :variant="reportPrintButtonEnabled ? 'primary' : 'secondary'"
            :disabled="!reportPrintButtonEnabled"
            @click="onReportPrintClickHandler"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </div>
      </div>
      <b-button
        variant="primary"
        class="mt-3"
        block
        @click="$bvModal.hide('print-menu')"
      >Закрыть
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BTabs, BTab, BButtonGroup, BButton, BModal, BFormGroup, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

// eslint-disable-next-line import/no-cycle
import { emptyDriverContract } from '@/views/drivers/driversData'
import DriverOperations from '@/views/drivers/components/DriverOperations.vue'
import DataView from '@/views/components/DataView.vue'
import DriverDebts from '@/views/drivers/components/DriverDebts.vue'
import DriverContractForm from './DriverContractForm.vue'
import BasicInformation from './components/DriverBasic.vue'
import Accounts from './components/DriverAccounts.vue'

export default {
  components: {
    DriverDebts,
    DriverOperations,
    BCard,
    BOverlay,
    BTabs,
    BTab,
    BButtonGroup,
    BButton,
    BModal,
    VSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    DriverContractForm,
    BasicInformation,
    Accounts,
    DataView,
  },
  data() {
    return {
      ready: false,
      driver: { ...emptyDriverContract },
      contractToPrint: null,
      bankToPrint: null,
      reportToPrint: {
        date_start: null,
        date_end: null,
      },
    }
  },
  computed: {
    driverDisplayName() {
      return [
        this.driver.last_name,
        this.driver.first_name,
        this.driver.middle_name,
      ].join(' ')
    },
    contractsOptions() {
      return this.driver.contracts ? this.driver.contracts.map(item => ({
        key: item,
        label: `${item.date_start}/${item.date_end}`,
      })) : []
    },
    bankOptions() {
      return this.driver.bank_details ? this.driver.bank_details.map(item => ({
        key: item,
        label: item.date_start,
      })) : []
    },
    reportPrintButtonEnabled() {
      return this.reportToPrint.date_start && this.reportToPrint.date_end
    },
  },
  mounted() {
    this.fetchDriver()
  },
  methods: {
    fetchDriver() {
      this.ready = false
      this.$axios.get(`drivers/${this.$route.params.id}?populate=carContract`)
        .then(response => {
          this.driver = { ...emptyDriverContract, ...response.data.data }
          this.ready = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: 'error-404' })
          }
        })
    },
    closeDriverForm() {
      this.$bvModal.hide('driver-form')
    },
    openDriverForm() {
      this.$bvModal.show('driver-form')
    },
    updatedDriverHandler(driver) {
      this.driver = { ...driver }
    },
    deleteDriverHandler() {
      this.$bvModal.msgBoxConfirm(`Удалить водителя ${this.driverDisplayName}?`)
        .then(result => {
          if (result) {
            this.$axios.delete(`drivers/${this.driver.id}`)
              .then(() => {
                this.$router.push({ name: 'driver-list' })
              })
          }
        })
    },
    navigateDrivers(type) {
      this.ready = false
      this.$axios.get(`drivers/${this.driver.id}/${type}`).then(response => {
        this.driver = response.data.data
        this.$router.replace({ name: 'driver-view', params: { id: this.driver.id } })
      }).catch(err => {
        const msg = err.response.status === 404 ? 'Водитель не найден!' : err.response.data.message
        this.$bvToast.toast(msg, {
          title: 'Ошибка',
          variant: 'danger',
          autoHideDelay: 3000,
        })
      }).finally(() => {
        this.ready = true
      })
    },
    nextHandler() {
      this.navigateDrivers('next')
    },
    prevHandler() {
      this.navigateDrivers('prev')
    },
    onBankPrintClickHandler() {
      this.$axios.post('/print/driver-bank', {
        driver_id: this.driver.id,
        bank: this.bankToPrint.key,
      }, {
        responseType: 'blob',
      })
    },
    onContractPrintClickHandler() {
      this.$axios.post('/print/driver-contract', {
        driver_id: this.driver.id,
        contract: this.contractToPrint.key,
      }, {
        responseType: 'blob',
      })
    },
    onReportPrintClickHandler() {
      this.$axios.post('/print/driver-act', {
        driver_id: this.driver.id,
        params: this.reportToPrint,
      }, {
        responseType: 'blob',
      })
    },
    onDriverFormHideHandler(event) {
      if (event.trigger === 'backdrop') {
        event.preventDefault()
      }
    },
  },
}
</script>

<style scoped>

</style>
